<template>
	<div class="exception-body flex align-items-center justify-content-center">
		<div class="exception-panel p-5 flex flex-column justify-content-center">
		<h4 class="font-bold">ÇIKIŞ YAPTINIZ</h4>
		<span class="line-height-3">Tekrar giriş yapmak isterseniz lütfen <router-link to="login"> buraya tıklayın</router-link></span>

		<img src="layout/images/pages/access-denied.svg" alt="access-denied" class="my-5" height="140">
		
		<Button label="Giriş Sayfası" @click="login"/>

		</div>
	</div>
</template>



<script>
import store from "@/store";
export default {
    name : "Logout",
    created() {
        store.commit("logout");
    },
    methods:{
        login() {
            this.$router.push({name:'login'});
        }
    }
}
</script>